import {
  AppExtensionSDK, init, locations, SidebarExtensionSDK,
} from '@contentful/app-sdk';
import { render } from 'react-dom';
import '@contentful/forma-36-react-components/dist/styles.css';
import { ConfigurationPanel } from './components/configuration-panel';
import { Sidebar } from './components/sidebar';

init((sdk) => {
  const root = document.getElementById('root');

  if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
    render(<ConfigurationPanel sdk={sdk as AppExtensionSDK} />, root);
  } else {
    render(<Sidebar sdk={sdk as SidebarExtensionSDK} />, root);
  }
});
