/* eslint-disable compat/compat */
import translate from 'deepl';
import { AxiosResponse } from 'axios';
import { SidebarExtensionSDK } from '@contentful/app-sdk';

interface DeepLResponseData {
  translations: DeepLResponseDataTranslation[];
}

interface DeepLResponseDataTranslation {
  detected_source_language: string;
  text: string;
}

const supportedLanguages = [
  'DE',
  'EN',
  'FR',
  'IT',
  'JA',
  'ES',
  'NL',
  'PL',
  'PT',
  'RU',
  'ZH',
];

export const transformLanguageString = (languageString: string) => languageString.split('-')[0].toUpperCase();

export const isLanguageDeepLCompatible = (language: string): boolean => supportedLanguages.indexOf(language) !== -1;

export const deepLTranslate = async (text: string, targetLanguage: string, sourceLanguage: string, deepLKeyL: string, sdk: SidebarExtensionSDK): Promise<DeepLResponseData | undefined> => {
  if (!deepLKeyL) {
    sdk.notifier.error('You need to provide an API key for DeepL!');
  }
  const truncatedTargetLanguageString = transformLanguageString(targetLanguage);
  const truncatedSourceLanguageString = transformLanguageString(sourceLanguage);

  if (isLanguageDeepLCompatible(truncatedSourceLanguageString) && isLanguageDeepLCompatible(truncatedTargetLanguageString)) {
    try {
      const response: AxiosResponse<DeepLResponseData> = await translate({
        text,
        target_lang: truncatedTargetLanguageString,
        source_lang: truncatedSourceLanguageString,
        auth_key: deepLKeyL,
        tag_handling: 'xml',
        split_sentences: '1',
      });

      return response.data;
    } catch (e) {
      return undefined;
    }
  }

  return undefined;
};
