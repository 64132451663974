import convert from 'xml-js';

import {
  Block, Document, Text, Hyperlink,
} from '@contentful/rich-text-types';

interface Mark {
  type: string;
}

interface DeepLXMLElement {
  type: 'element';
  name: string;
  attributes : Record<string, string>;
  elements: Array<DeepLXMLElement | DeepLXMLTextElement>;
}

interface DeepLXMLTextElement {
  type: 'text';
  text: string;
}

const formattingMarks = ['bold', 'italic', 'underline', 'code'];

const nodeMarkupTypes = [
  'document',
  'paragraph',
  'bold',
  'italic',
  'underline',
  'code',
  'unordered-list',
  'list-item',
  'paragraph',
  'ordered-list',
  'blockquote',
  'hr',
  'a',
  'hyperlink',
  'heading-1',
  'heading-2',
  'heading-3',
  'heading-4',
  'heading-5',
  'heading-6',
];

export const jsonToXML = (fields: {[key: string]: any}, sourceLanguage: string) => {
  let xml = '';
  Object.entries(fields).forEach(([fieldName, fieldValue]) => {
    if (typeof fieldValue[sourceLanguage] === 'string') {
      // simple text
      xml += encapsulate(fieldName, fieldValue[sourceLanguage] as string);
    } else {
      // rich text
      xml += encapsulate(fieldName, objectToXMLRecursive([fieldValue[sourceLanguage]]));
    }
  });
  xml = encapsulate('root', xml);
  return xml;
};

export function XMLToJson(xml: string, targetLanguage: string) {
  const contentfulJson: {[key: string]: any} = {};
  const json = convert.xml2json(xml, { compact: false, spaces: 4 });
  const jsonAsObject = JSON.parse(json);
  const firstElementInJsonObject = jsonAsObject.elements[0]; // go inside root element

  Object.values(firstElementInJsonObject.elements).forEach((node: any) => {
    const newElement: {[key: string]: any} = {};
    if (node.elements && node.elements.length === 1 && node.elements[0].type === 'text') {
      newElement[targetLanguage] = node.elements[0].text;
    } else {
      // eslint-disable-next-line prefer-destructuring
      newElement[targetLanguage] = jsonToContentfulJsonRecursive(node.elements)[0];
    }
    contentfulJson[node.name] = newElement;
  });

  return contentfulJson;
}

/* eslint-disable consistent-return */
// I don't understand how to satisfy this rule with recursive functions...
const extractFormattedText = (
  elementList: Array<DeepLXMLElement | DeepLXMLTextElement>,
  marksList: Array<Mark>,
): { value: string, marks: Array<Mark> } | undefined => {
  if (!elementList) {
    return {
      value: ' ',
      marks: marksList,
    };
  }

  if (elementList.length === 1) {
    if (elementList[0].type === 'text') {
      return {
        value: elementList[0].text,
        marks: marksList,
      };
    }

    if (elementList[0].name && elementList[0].elements && formattingMarks.indexOf(elementList[0].name) !== -1) {
      marksList.push({ type: elementList[0].name });
      return extractFormattedText(elementList[0].elements, marksList);
    }
  } else {
    console.error('Length error in formatted text: ', elementList);
  }
};
/* eslint-enable consistent-return */

const jsonToContentfulJsonRecursive = (jsonList: Array<DeepLXMLElement | DeepLXMLTextElement>): Array<Document | Block | Text | unknown> => {
  const contentfulJsonList: Array<Document | Block | Text | unknown> = [];

  if (!jsonList) {
    return contentfulJsonList;
  }

  Object.values(jsonList).forEach((node: DeepLXMLElement | DeepLXMLTextElement): void => {
    if (node.type === 'element') {
      if (node.name === 'hyperlink') {
        contentfulJsonList.push({
          nodeType: node.name,
          data: { uri: node.attributes.href },
          content: jsonToContentfulJsonRecursive(node.elements),
        });
      } else
      if (formattingMarks.indexOf(node.name) !== -1) {
        const formattedTextObject = extractFormattedText(node.elements, [{ type: node.name }]);

        if (formattedTextObject) {
          contentfulJsonList.push({
            nodeType: 'text',
            value: cleanStringFromSpaces(formattedTextObject.value),
            marks: formattedTextObject.marks,
            data: {},
          });
        }
      } else if (nodeMarkupTypes.indexOf(node.name) !== -1) {
        contentfulJsonList.push({
          nodeType: node.name,
          data: {},
          content: jsonToContentfulJsonRecursive(node.elements),
        });
      }
    } else if (node.type === 'text') {
      contentfulJsonList.push({
        nodeType: 'text',
        value: cleanStringFromSpaces(node.text),
        marks: [],
        data: {},
      });
    }
  });

  return contentfulJsonList;
};

const encapsulate = (nodeType: string, nodeContent: string, extra?: string) => `<${nodeType}${extra ? ` ${extra}` : ''}>${nodeContent}</${nodeType}>`;

const objectToXMLRecursive = (obj: [Document] | Block[] | Text[] | Hyperlink[]) => {
  let xmlString = '';
  obj.forEach((node: Block | Text | Hyperlink) => {
    if (node.nodeType === 'hyperlink') {
      xmlString += encapsulate(`${node.nodeType}`, objectToXMLRecursive((node as Hyperlink).content), `href="${node.data.uri}"`);
    } else if (node.nodeType === 'text') {
      if (node.marks.length > 0) {
        xmlString += listToNested(node.marks, node.value);
      } else {
        xmlString += cleanStringFromSpaces(node.value);
      }
    } else {
      xmlString += encapsulate(node.nodeType, objectToXMLRecursive((node as Document & Block).content));
    }
  });
  return xmlString;
};

const listToNested = (list: Mark[], finalElement: string): string => {
  if (list.length > 1) {
    return encapsulate(list[0].type, listToNested(list.slice(1), finalElement));
  }
  return encapsulate(list[0].type, finalElement);
};

const cleanStringFromSpaces = (value: string) => `${value.replace(/^\s+|\s+$/g, '')} `;
