import {
  Form, HelpText, Note, SelectField, TextField, Option, CheckboxField, FieldGroup,
} from '@contentful/forma-36-react-components';
import {
  AppExtensionSDK, CollectionResponse, ContentType,
} from '@contentful/app-sdk';
import React, {
  useEffect, useState,
} from 'react';
import { FieldConfiguration } from '../interfaces';
import '../styles.scss';

interface ConfigurationPanelProps {
  sdk: AppExtensionSDK;
}

interface Parameters {
  installed: boolean;
  fieldConfiguration: FieldConfiguration;
  key: string;
}

export const ConfigurationPanel: React.FC<ConfigurationPanelProps> = ({ sdk }) => {
  const [availableContentTypes, setAvailableContentTypes] = useState<ContentType[]>([]);
  const [isInstalled, setIsInstalled] = useState(false);
  const [deeplApiKey, setDeeplApiKey] = useState('');
  const [fieldConfiguration, setFieldConfiguration] = useState<FieldConfiguration>({});
  const [activeCT, setActiveCT] = useState<ContentType>();

  const renderAvailableFieldsCheckboxes = () => {
    if (activeCT) {
      return <FieldGroup>
        {activeCT.fields.map((field) => {
          if (field.localized) {
            if (!fieldConfiguration[activeCT.sys.id]) {
              setFieldConfiguration((prevState) => ({
                ...prevState,
                [activeCT.sys.id]: {},
              }));
            }

            return (
              <CheckboxField
                key={field.id}
                checked={fieldConfiguration[activeCT.sys.id]?.[field.id]}
                id={field.id}
                labelText={field.name}
                value={field.id}
                onChange={async (e) => {
                  setFieldConfiguration((prevState) => ({
                    ...prevState,
                    [activeCT.sys.id]: {
                      ...prevState[activeCT.sys.id],
                      [field.id]: (e.target as HTMLInputElement).checked,
                    },
                  }));
                }}
              />
            );
          }
          return null;
        })}
      </FieldGroup>;
    }
    return null;
  };

  const contentModelsAvailbleHTMLOptions = (alreadyExistingCT: ContentType) => availableContentTypes.map((CT: ContentType) => {
    if (CT.sys.id !== alreadyExistingCT.sys.id) {
      return <Option key={CT.sys.id} value={JSON.stringify(CT)}>{CT.name}</Option>;
    }
    return null;
  });

  const onConfigure = (): { parameters: Parameters } => ({
    parameters: {
      key: deeplApiKey,
      fieldConfiguration,
      installed: isInstalled,
    },
  });

  useEffect(() => {
    sdk.app.onConfigure(onConfigure);

    (async function getParams() {
      const params: Parameters | null = await sdk.app.getParameters();
      if (params) {
        setDeeplApiKey(params.key);
        setFieldConfiguration(params.fieldConfiguration);
      }
    }());

    (async function getInstallStatus() {
      setIsInstalled(await sdk.app.isInstalled());
    }());

    sdk.app.setReady();

    (async function getContentTypes() {
      setAvailableContentTypes(
        (await sdk.space.getContentTypes() as CollectionResponse<ContentType>).items,
      );
    }());
  }, []);

  useEffect(() => {
    if (availableContentTypes.length > 0) {
      setActiveCT(availableContentTypes[0]);
    }
  }, [availableContentTypes]);

  useEffect(() => {
    // need to reset the onConfigure function everytime the user changes inputs
    // to bypass stale closures
    sdk.app.onConfigure(onConfigure);
  }, [deeplApiKey, fieldConfiguration]);

  useEffect(() => {
    // initialize active CT in config state if it doesn't exist there yet
    if (activeCT && !fieldConfiguration[activeCT.sys.id]) {
      setFieldConfiguration((prevState) => ({
        ...prevState,
        [activeCT?.sys.id]: {},
      }));
    }
  }, [activeCT]);

  return (
    <>
      {availableContentTypes && availableContentTypes.length > 0 ? (
        <div className='app-config'>
          <Note className='note' noteType='primary' title="About the app's configuration">
            After setting up the fields, you have to activate the new sidebar functions for all corresponding content models.
            For each affected content model, please go to its configuration, select the &quot;Sidebar&quot; tab, select &quot;Use custom sidebar&quot;,
            add the new additional widget, save the changes.
            You can then use the translation functions on the sidebar while editing your content.
          </Note>
          <Form>
            <TextField
              id='apiKey'
              labelText='DeepL API key'
              name='apiKey'
              required={true}
              textInputProps={{ type: 'password' }}
              value={deeplApiKey || ''}
              onChange={(e) => {
                setDeeplApiKey((e.target as HTMLInputElement).value);
              }}
            />
            <HelpText>Please specify your API key for the translation provider (DeepL).</HelpText>
            <SelectField
              required
              id='content-model'
              labelText='Content model'
              name='content-model'
              value={JSON.stringify(availableContentTypes[0])}
              onChange={(e) => {
                setActiveCT(JSON.parse((e.target as HTMLInputElement).value));
              }}
            >
              <Option
                value={JSON.stringify(availableContentTypes[0])}>
                {availableContentTypes[0].name}
              </Option>
              {contentModelsAvailbleHTMLOptions(availableContentTypes[0])}
            </SelectField>
            <HelpText>
              For each model, please select the localized fields you wish to activate for translation.
              Only the selected fields will be translated while using the translation function for each content type.
            </HelpText>
            <div>
              {renderAvailableFieldsCheckboxes()}
            </div>
          </Form>
        </div>
      ) : <Note noteType='primary' title='Please wait'>
          loading ...
      </Note>}
    </>
  );
};
