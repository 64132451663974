import {
  Button, Form, FormLabel, SelectField, Option, Note, FieldGroup, CheckboxField, List, ListItem,
} from '@contentful/forma-36-react-components';
import { EntryFieldAPI, SidebarExtensionSDK } from '@contentful/app-sdk';
import { useEffect, useRef, useState } from 'react';
import { deepLTranslate, isLanguageDeepLCompatible, transformLanguageString } from '../utils/deepl-service';
import {
  Fields, InstallationTypeOverride, TranslationConfig, TranslationRequestBody,
} from '../interfaces';
import { jsonToXML, XMLToJson } from '../utils/json-xml-mapping';
import { updateEntry } from '../utils/update-entry';

interface SidebarProps {
  sdk: SidebarExtensionSDK;
}

export const Sidebar: React.FC<SidebarProps> = ({
  sdk,
}) => {
  const [translationConfig, setTranslationConfig] = useState<TranslationConfig>({});
  const [translationRequestBody, setTranslationRequestBody] = useState<TranslationRequestBody>({
    entryId: '',
    environmentId: '',
    fields: {},
    requestedLanguages: [],
    sourceLanguage: '',
    spaceId: '',
  });
  const [deepLApiKey, setDeepLApiKey] = useState('');
  const detachFunctions = useRef<Array<() => void>>();

  const getFieldValuesForLocale = (
    locale: string,
  ): Fields | null => {
    if (Object.keys(translationConfig).length) {
      const contentTypeId = sdk.entry.getSys().contentType.sys.id;
      const fieldsToBeTranslated: Fields = {};
      let thereIsAnEmptyField = false;

      if (!translationConfig[contentTypeId] || Object.keys(translationConfig[contentTypeId]).length === 0) {
        sdk.notifier.error('There are no fields to translate!');
        return null;
      }

      Object.entries(sdk.entry.fields).forEach((fieldEntry: [string, EntryFieldAPI]) => {
        const [currentFieldName, currentField] = fieldEntry;

        if (translationConfig[contentTypeId][currentFieldName]) {
          try {
            const value = currentField.getValue(locale);
            if (value) {
              if (!fieldsToBeTranslated[currentFieldName]) {
                fieldsToBeTranslated[currentFieldName] = {};
              }
              fieldsToBeTranslated[currentFieldName][locale] = currentField.getValue(locale);
            }
          } catch (_e) {
            thereIsAnEmptyField = true;
          }
        }
      });

      if (thereIsAnEmptyField) {
        if (Object.keys(fieldsToBeTranslated).length === 0) {
          return null;
        }
        sdk.notifier.error('Some fileds have no value in source language');
      }

      return fieldsToBeTranslated;
    }
    return null;
  };

  const initializeTranslationRequestBody = () => {
    const fields = getFieldValuesForLocale(sdk.locales.default);

    setTranslationRequestBody({
      entryId: sdk.ids.entry,
      spaceId: sdk.ids.space,
      environmentId: sdk.ids.environment,
      fields: fields ?? {},
      requestedLanguages: [],
      sourceLanguage: sdk.locales.default,
    });
  };

  const updateFieldsInState = (): void => {
    const fields = getFieldValuesForLocale(translationRequestBody.sourceLanguage);

    if (fields) {
      setTranslationRequestBody((prevState) => ({
        ...prevState,
        fields,
      }));
    }
  };

  const getOtherAvailableLanguages = (alreadyExistLanguage: string) => sdk.locales.available.map((availableLanguage: string) => {
    if (availableLanguage !== alreadyExistLanguage) {
      return availableLanguage;
    }
    return null;
  });

  const sendTranslationRequest = async (): Promise<void> => {
    if (translationRequestBody) {
      const { sourceLanguage, requestedLanguages, fields } = translationRequestBody;
      if (sourceLanguage == null
        || requestedLanguages.length === 0) {
        sdk.notifier.error('Please choose at least one language to translate to.');
      } else if (requestedLanguages.length > getOtherAvailableLanguages(sourceLanguage).length
        || requestedLanguages.includes(sourceLanguage)) {
        sdk.notifier.error('Requested languages should not include source language');
      } else if (fields === null) {
        sdk.notifier.error('There is no content in this source language');
      } else {
        requestedLanguages.forEach(async (requestedLanguage) => {
          const response = await deepLTranslate(
            jsonToXML(
              fields,
              sourceLanguage,
            ),
            requestedLanguage,
            sourceLanguage,
            deepLApiKey,
            sdk,
          );

          if (response) {
            sdk.notifier.success('Translation request submitted successfully');
            const responseJson = XMLToJson(response.translations[0].text, requestedLanguage);
            updateEntry(responseJson, requestedLanguage, sdk);
          } else {
            sdk.notifier.error('Something went wrong.. Please try again in a bit.');
          }
        });
      }
    } else {
      sdk.notifier.error('Error, empty request');
    }
  };

  const onSourceLanguageChange = (event: React.ChangeEvent) => {
    const fields = getFieldValuesForLocale((event.target as HTMLInputElement).value);

    setTranslationRequestBody((prevState) => ({
      ...prevState,
      fields: fields ?? prevState.fields,
      sourceLanguage: (event.target as HTMLInputElement).value,
    }));
  };

  const onTargetLanguageChange = (event: React.ChangeEvent) => {
    const { value: locale, checked } = event.target as HTMLInputElement;

    setTranslationRequestBody((prevState) => {
      const oldRequestedLanguages = [...prevState?.requestedLanguages];

      const requestedLanguages = checked
        // add locale to array
        ? [
          ...prevState?.requestedLanguages,
          locale,
        ]
        // remove locale from array
        : [
          ...oldRequestedLanguages.slice(0, oldRequestedLanguages.indexOf(locale)),
          ...oldRequestedLanguages.slice(oldRequestedLanguages.indexOf(locale) + 1),
        ];

      return {
        ...prevState,
        requestedLanguages,
      };
    });
  };

  const submitHandler = () => {
    updateFieldsInState();
    sendTranslationRequest();
  };

  // when a user changes the value of a field, the state needs to be updated accordingly
  // contentful's SDK provides the `onValueChanged` function for fields
  // we need to put our state update into that function
  const setEntryFieldsChangeListener = () => {
    const detachers: Array<() => void> = [];

    Object.values(sdk.entry.fields).forEach((field) => {
      field.locales.forEach((locale) => {
        // we need to be able to reset this listener function to bypass stale closures
        // `onValueChanged` returns a function to detach (delete) its listener
        // we need to save this detacher function in order to use it later

        const detacher = field.onValueChanged(locale, () => {
          updateFieldsInState();
        });

        detachers.push(detacher);
      });
    });

    detachFunctions.current = detachers;
  };

  const clearEntryFieldChangeListeners = () => {
    detachFunctions.current?.forEach((detachFunction) => {
      detachFunction();
    });
  };

  const thereAreFieldsToBeTranslated = () => {
    if (translationConfig) {
      const currentContentTypeId = sdk.entry.getSys().contentType.sys.id;
      const currentContentType = translationConfig[currentContentTypeId];

      // current CT is in list of CTs AND has fields configured
      if (currentContentType && Object.keys(currentContentType).length > 0) {
        // current CT has at least one field === true
        return Object.values(currentContentType).some((field) => field === true);
      }
      return false;
    }
    return false;
  };

  const renderSourceLanguageHTMLOptions = () => sdk.locales.available.map((availableLanguage) => {
    if (isLanguageDeepLCompatible(transformLanguageString(availableLanguage))) {
      return (
        <Option key={availableLanguage} value={availableLanguage}>
          {availableLanguage}
        </Option>
      );
    }
    return null;
  });

  const renderTargetLanguageHTMLCheckboxFields = () => (
    <FieldGroup>
      {sdk.locales.available.map((locale) => (
        <CheckboxField
          key={locale}
          checked={translationRequestBody?.requestedLanguages.some((lang) => lang === locale)}
          id={locale}
          labelText={locale}
          name={locale}
          value={locale}
          onChange={onTargetLanguageChange}
        />
      ))}
    </FieldGroup>
  );

  const renderSelectedFields = (): JSX.Element => {
    const currentContentTypeId = sdk.entry.getSys().contentType.sys.id;
    const currentContentType = translationConfig[currentContentTypeId];

    return <List>
      {Object.entries(currentContentType).map((fieldEntry) => {
        if (fieldEntry[1] === true) {
          return <ListItem>{fieldEntry[0]}</ListItem>;
        }
        return null;
      })}
    </List>;
  };

  // componentDidMount
  useEffect(() => {
    if (window) {
      sdk.window.startAutoResizer();
    }

    const { fieldConfiguration, key } = sdk.parameters.installation as InstallationTypeOverride;

    if (fieldConfiguration) {
      setTranslationConfig(fieldConfiguration);
    }
    if (key) {
      setDeepLApiKey(key);
    }
  }, []);

  useEffect(() => {
    initializeTranslationRequestBody();
    setEntryFieldsChangeListener();
  }, [translationConfig]);

  useEffect(() => {
    if (translationRequestBody.sourceLanguage) {
      setEntryFieldsChangeListener();
    }

    return () => {
      clearEntryFieldChangeListeners();
    };
  }, [translationRequestBody.sourceLanguage]);

  return <>
    {(sdk.parameters.installation as InstallationTypeOverride).key
      ? thereAreFieldsToBeTranslated()
        ? <Form>
          <SelectField
            required
            id='source-language'
            labelText='Translate from'
            name='source-language'
            value={sdk.locales.default}
            onChange={onSourceLanguageChange}
          >
            {renderSourceLanguageHTMLOptions()}
          </SelectField>
          <FormLabel htmlFor=''>
              Translate into
            {renderTargetLanguageHTMLCheckboxFields()}
          </FormLabel>
          <Button
            type='submit'
            onClick={submitHandler}>
            Translate content
          </Button>
          <Note noteType='primary' title=''>
            Please be aware that the content for all selected target
            languages and configured fields will be overwritten.
            {renderSelectedFields()}
          </Note>
        </Form>
        : <Note noteType='warning' title='Warning!'>
          You didn&lsquo;t select any field to be translated in this content model. Please, select which fields should be translated from the configuration interface.
        </Note>
      : <Note noteType='negative' title='Warning!'>
        You don&lsquo;t have a deepl key <br />
                Please, enter your deepl key in the configuration page.
      </Note>}
  </>;
};
